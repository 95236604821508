export const routePrefixes = {
    pl: "pogoda24",
};

export const dayStringMultiple = {
    pl: "weekendowa",
    dach: "wochenend",
} as const;

export const dayStringSingle = {
    // pl: "jutro",
    dach: "morgen",
} as const;
