import type { Nullable, ObjectToUnionType } from "../@types/generic";
import type { MobileWebType } from "../helpers/types";
import type { SkiArea } from "../network/matlocq/types/skiArea";

export type InitialConfig = {
    i18n?: {
        use12HourFormat?: boolean;
        locale?: string;
    };
    ucSettingsId?: Nullable<string>;
    isPay?: boolean;
    mobileWebType?: MobileWebType;
};

export type SendEvent = ObjectToUnionType<
    "type",
    {
        webviewLoaded: undefined;
        consentStatus: {
            consent: "full" | "none";
        };
        "skiArea:data": {
            data: {
                id: SkiArea["id"];
                sponsoring: {
                    isSponsored: SkiArea["sponsoring"]["isSponsored"];
                };
            };
        };
        geolocateUser: undefined;
        navigate: {
            prevUrl: string;
            url: string;
        };
    }
>;

export type RawData = {
    rawData: unknown;
};

export enum Webview {
    app = "app",
    // standalone = "standalone",
}
// export type WebviewType = keyof typeof Webview;

export type UserLocation = {
    lng?: Nullable<number>;
    lat?: Nullable<number>;
};
