import type { UserSessionData } from "@usercentrics/cmp-browser-sdk";
import type { InitialConfig, RawData, UserLocation } from "./types";

/**
 * Type guard to ensure config has the correct shape and types.
 * @param config unknown object from the native app.
 * @returns
 */

export const isInitialConifg = (config: unknown): config is InitialConfig => {
    if (typeof config !== "object") {
        return false;
    }

    if (config === null) {
        return false;
    }

    if (Array.isArray(config)) {
        return false;
    }

    return (
        config.hasOwnProperty("i18n") &&
        config.hasOwnProperty("isPay") &&
        config.hasOwnProperty("mobileWebType") &&
        config.hasOwnProperty("ucSettingsId")
    );
};

export const isUserSessionData = (data: unknown): data is UserSessionData => {
    if (typeof data !== "object") {
        return false;
    }

    if (data === null) {
        return false;
    }

    if (Array.isArray(data)) {
        return false;
    }

    return (
        data.hasOwnProperty("ccpa") &&
        data.hasOwnProperty("consents") &&
        data.hasOwnProperty("controllerId") &&
        data.hasOwnProperty("language") &&
        data.hasOwnProperty("tcf")
    );
};

export const isRawData = (data: unknown): data is RawData => {
    if (typeof data !== "object") {
        return false;
    }

    if (data === null) {
        return false;
    }

    if (Array.isArray(data)) {
        return false;
    }

    return data.hasOwnProperty("rawData");
};

export const isUserLocation = (location: unknown): location is UserLocation => {
    if (typeof location !== "object") {
        return false;
    }

    if (location === null) {
        return false;
    }

    if (Array.isArray(location)) {
        return false;
    }

    return location.hasOwnProperty("lat") && location.hasOwnProperty("lng");
};
