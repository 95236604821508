import type { NextPageContext } from "next/dist/shared/lib/utils";

export const sendError = (
    error: unknown,
    data: Record<string, string> | NextPageContext["res"] = {}
): void => {
    if (process.env.NEXT_PUBLIC_DEPLOY_ENV) {
        let customAttributes = {
            "service.version":
                process.env.NEXT_PUBLIC_BUILD_NUMBER ?? "unknown",
            ...data,
        };
        if (error) {
            customAttributes = Object.assign({}, customAttributes, data, {
                // @ts-expect-error - error is not always an instance of Error
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unnecessary-condition
                "custom.stack": error?.stack ?? "",
                // @ts-expect-error - error is not always an instance of Error
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unnecessary-condition
                "custom.name": error?.name,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                "custom.message":
                    // @ts-expect-error - error is not always an instance of Error
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    typeof error?.message === "string"
                        ? // @ts-expect-error - error is not always an instance of Error

                          error.message
                        : "unknown",
                // @ts-expect-error - error is not always an instance of Error
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                "custom.cause": JSON.stringify(error?.cause ?? ""),
            });
        }

        //It must be lib-dom window due to build issues. Do not use getWindow() here.
        if (typeof window === "undefined") {
            // eslint-disable-next-line no-console
            console.log("[APM Error]", error);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            newrelic.noticeError(error as Error, customAttributes);
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        } else if (window.newrelic) {
            window.newrelic.noticeError(
                error as Error,
                customAttributes as never
            );
        }
    }
};
