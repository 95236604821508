import { use } from "react";
import { ApplicationStateContext } from ".";
import type { CountryType, Nullable } from "../../@types/generic";
import type { PageParams } from "../../app/types";
import {
    Webview,
    type InitialConfig,
    type UserLocation,
} from "../../bridge/types";
import type { PageType, PrebidAB } from "./types";

export const usePageType = (): PageType => {
    const context = use(ApplicationStateContext);
    return context.pageType;
};

export const usePageParams = (): PageParams => {
    const context = use(ApplicationStateContext);
    return context.pageParams;
};

export const useCountry = (): CountryType => {
    const context = use(ApplicationStateContext);
    return context.country;
};

export const useIsMobileSafari = (): boolean => {
    const context = use(ApplicationStateContext);
    return context.isMobileSafari;
};

export const useIsPersistingForbidden = (): boolean => {
    const context = use(ApplicationStateContext);
    return context.isPersistingForbidden;
};

export const useIsWebview = (): Nullable<boolean> => {
    const context = use(ApplicationStateContext);
    return context.webview === Webview.app;
};

export const useInitialConfig = (): Nullable<InitialConfig> => {
    const context = use(ApplicationStateContext);
    return context.initialConfig;
};

export const useUserLocation = (): UserLocation | undefined => {
    const context = use(ApplicationStateContext);
    return context.userLocation;
};

export const usePrebidAB = (): PrebidAB => {
    const context = use(ApplicationStateContext);
    return context.prebid;
};

export const useSetPrebidAB = (): Nullable<
    React.Dispatch<React.SetStateAction<Nullable<PrebidAB>>>
> => {
    const context = use(ApplicationStateContext);
    return context.setPrebid;
};
