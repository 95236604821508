import type { CountryType, Nullable } from "../../@types/generic";
import type { PageParams } from "../../app/types";

export const extractPageParamsFromPathname = (
    pathname: Nullable<string>,
    country: CountryType
): PageParams => {
    if (!pathname) {
        return {};
    }

    if (country === "pl") {
        return extractPl(pathname);
    }

    return extractDACH(pathname);
};

const extractDACH = (pathname: string): PageParams => {
    /**
     * FORECAST
     */
    let params = pathname.match(
        /\/wetter_aktuell\/wettervorhersage\/(?<daysMultiple>3|7|16)_tagesvorhersage\/(?<seoString>.*)\/(?<code>[A-Za-z0-9]{2,}).html/
    );
    if (params) {
        return Object.assign({}, params.groups);
    }

    params = pathname.match(
        /\/wetter_aktuell\/wettervorhersage\/(?<dayStringMultiple>wochenend)_vorhersage\/(?<seoString>.*)\/(?<code>[A-Za-z0-9]{2,}).html/
    );
    if (params) {
        return Object.assign({}, params.groups);
    }

    params = pathname.match(
        /\/wetter_aktuell\/wettervorhersage\/(?<dayStringSingle>morgen)\/(?<seoString>.*)\/(?<code>[A-Za-z0-9]{2,}).html/
    );
    if (params) {
        return Object.assign({}, params.groups);
    }

    params = pathname.match(
        /\/wetter_aktuell\/wettervorhersage\/in-(?<daysSingle>[2-6])-tagen\/(?<seoString>.*)\/(?<code>[A-Za-z0-9]{2,}).html/
    );
    if (params) {
        return Object.assign({}, params.groups);
    }

    params = pathname.match(
        /\/(?<seoString>.*)\/(?<code>[A-Za-z0-9]{2,}).html/
    );
    if (params) {
        return Object.assign({}, params.groups);
    }

    /**
     * HD LIVE WEBCAMS
     */
    params = pathname.match(
        /hd-live-webcams\/(?<seoString>[a-zA-Z0-9-\/]+)\/(?<id>[a-z0-9]+)/
    );
    if (params) {
        return Object.assign({}, params.groups);
    }

    /**
     * SKIAREAS
     */
    params = pathname.match(
        /\/skigebiete\/(?<countryName>[a-zA-Z-]+)\/(?<name>[a-zA-Z0-9-]+)-(?<id>[0-9]+)/
    );
    if (params) {
        return Object.assign({}, params.groups);
    }
    params = pathname.match(
        /\/skigebiete\/(?<countryName>[a-zA-Z-]+)\/(?<name>[a-zA-Z0-9-]+)-(?<id>[0-9]+)\/pisten-lifte/
    );
    if (params) {
        return Object.assign({}, params.groups);
    }
    params = pathname.match(
        /\/skigebiete\/(?<countryName>[a-zA-Z-]+)\/(?<name>[a-zA-Z0-9-]+)-(?<id>[0-9]+)\/wetter-schnee/
    );
    if (params) {
        return Object.assign({}, params.groups);
    }

    return {};
};

const extractPl = (pathname: string): PageParams => {
    /**
     * FORECAST
     */
    let params = pathname.match(
        /\/pogoda24\/prognoza-(?<daysMultiple>7|16)-dni\/(?<seoString>.*)\/(?<code>[A-Za-z0-9]{2,})/
    );
    if (params) {
        return Object.assign({}, params.groups);
    }

    params = pathname.match(
        /\/pogoda24\/prognoza-(?<dayStringMultiple>weekendowa)\/(?<seoString>.*)\/(?<code>[A-Za-z0-9]{2,})/
    );
    if (params) {
        return Object.assign({}, params.groups);
    }

    // today
    params = pathname.match(
        /\/pogoda24\/prognoza-dzisiaj\/(?<seoString>.*)\/(?<code>[A-Za-z0-9]{2,})/
    );
    if (params) {
        return Object.assign({}, params.groups);
    }

    return {};
};
