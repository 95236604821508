import type { UserSessionData } from "@usercentrics/cmp-browser-sdk";
import { sendError } from "../components/ErrorBoundary/helpers";
import { debug } from "../helpers/debug";
import { getWindow } from "../helpers/getWindow";
import type { MessageHandlers, WebviewToNativeAppBridge } from "./bridgeTypes";
import { isInitialConifg, isRawData, isUserSessionData } from "./typeGuards";
import type { InitialConfig, SendEvent } from "./types";

const getAndroidBrdige = (): WebviewToNativeAppBridge | undefined => {
    const window = getWindow();
    return window?.webviewToNativeAppBridge;
};
const getIosBridge = (): MessageHandlers | undefined => {
    const window = getWindow();
    return window?.webkit?.messageHandlers;
};

/**
 * Sends an event to the native app.
 * @param event - The event to send to the native app.
 */
export const sendEventToNativeApp = (event: SendEvent): void => {
    const androidBridge = getAndroidBrdige();
    const iosBridge = getIosBridge();
    try {
        // eslint-disable-next-line testing-library/no-debugging-utils
        debug("[bridge] > sendEventToNativeApp", event);
        if (androidBridge) {
            androidBridge.sendEvent?.(JSON.stringify(event));
        } else if (iosBridge) {
            // we dont need to await the promise here
            void iosBridge.sendEvent?.postMessage?.(event);
        }
    } catch (e) {
        sendError(e);
    }
};

export const getInitialConfigFromNativeApp =
    async (): Promise<InitialConfig> => {
        const androidBridge = getAndroidBrdige();
        const iosBridge = getIosBridge();
        let configObj: InitialConfig = {};

        try {
            if (androidBridge) {
                const config = androidBridge.getInitialConfig?.() ?? null;
                if (typeof config === "string") {
                    const obj: unknown = JSON.parse(config);
                    if (isInitialConifg(obj)) {
                        configObj = obj;
                    }
                }
            } else if (iosBridge) {
                const config =
                    await iosBridge.getInitialConfig?.postMessage?.(null);
                if (isInitialConifg(config)) {
                    configObj = config;
                }
            }
        } catch (e) {
            sendError(e);
        }

        // eslint-disable-next-line testing-library/no-debugging-utils
        debug("[bridge] > getInitialConfigFromNativeApp", configObj);
        return configObj;
    };

export const getUserSessionDataFromNativeApp = async (): Promise<
    UserSessionData | undefined
> => {
    const androidBridge = getAndroidBrdige();
    const iosBridge = getIosBridge();
    let userSessionData: UserSessionData | undefined;

    try {
        if (androidBridge) {
            const sessionDataStr =
                androidBridge.getUsercentricsUserSessionData?.() ?? "";
            if (typeof sessionDataStr === "string") {
                const sessionData: unknown = JSON.parse(sessionDataStr);
                if (isUserSessionData(sessionData)) {
                    userSessionData = sessionData;
                }
            }
        } else if (iosBridge) {
            const sessionData =
                await iosBridge.getUsercentricsUserSessionData?.postMessage?.(
                    null
                );
            if (isRawData(sessionData)) {
                if (
                    typeof sessionData.rawData === "string" &&
                    isUserSessionData(JSON.parse(sessionData.rawData))
                ) {
                    userSessionData = JSON.parse(
                        sessionData.rawData
                    ) as UserSessionData;
                }
            }
        }
    } catch (e) {
        sendError(e);
    }

    // eslint-disable-next-line testing-library/no-debugging-utils
    debug("[bridge] > getUserSessionDataFromNativeApp", userSessionData);
    return userSessionData;
};
