import type { CountryType, Nullable } from "../@types/generic";

export const extractCountry = (
    hostname?: Nullable<string>,
    pathname?: Nullable<string>
): CountryType => {
    const availableProductsViaPathname: Array<"pogoda24"> = ["pogoda24"];

    if (pathname) {
        const product = availableProductsViaPathname.find((_product) =>
            pathname.startsWith(`/${_product}/`)
        );

        if (product === "pogoda24") {
            return "pl";
        }
    }

    if (typeof hostname !== "string") {
        return "de";
    }

    const allowedAT = [".at.qa.wetter.com", "at.wetter.com", "at.localhost"];
    const allowedCH = [".ch.qa.wetter.com", "ch.wetter.com", "ch.localhost"];

    if (allowedAT.some((host) => hostname.includes(host))) {
        return "at";
    } else if (allowedCH.some((host) => hostname.includes(host))) {
        return "ch";
    }

    return "de";
};
