import { useSearchParams } from "next/navigation";
import { useEffect, type Dispatch, type SetStateAction } from "react";
import type { Nullable } from "../@types/generic";
import type { ApplicationStateContextType } from "../components/ApplicationState/types";
import { debug } from "../helpers/debug";
import { getWindow } from "../helpers/getWindow";
import { getInitialConfigFromNativeApp, sendEventToNativeApp } from "./helpers";
import { isUserLocation } from "./typeGuards";
import { Webview, type InitialConfig, type UserLocation } from "./types";

export const useSetupBridge = (
    contextValue: ApplicationStateContextType,
    setInitialConfig: Dispatch<SetStateAction<Nullable<InitialConfig>>>,
    setUserLocation: Dispatch<SetStateAction<UserLocation | undefined>>
): void => {
    const window = getWindow();
    const searchParams = useSearchParams();

    useEffect(() => {
        if (
            window &&
            contextValue.webview === Webview.app &&
            contextValue.initialConfig === null
        ) {
            void (async (): Promise<void> => {
                if (searchParams.get("webviewFakeBridge") === "true") {
                    const mockData = await import("./mockData");

                    window.webviewToNativeAppBridge =
                        window.webviewToNativeAppBridge || {
                            getInitialConfig: (): unknown =>
                                JSON.stringify(mockData.initalConfig),
                            getUsercentricsUserSessionData: (): unknown =>
                                mockData.userSessionData,
                        };
                }

                // apply methods to be callable from native apps
                window.nativeAppToWebviewBridge =
                    window.nativeAppToWebviewBridge || {
                        setUserLocation: (location: unknown): void => {
                            // eslint-disable-next-line testing-library/no-debugging-utils
                            debug("[bridge] setUserLocation", location);

                            if (isUserLocation(location)) {
                                setUserLocation(location);
                            } else {
                                setUserLocation(undefined);
                            }
                        },
                    };

                // get config provided by apps
                const config = await getInitialConfigFromNativeApp();
                setInitialConfig(config);

                sendEventToNativeApp({
                    type: "webviewLoaded",
                });
            })();
        }
    }, [window, contextValue, searchParams, setInitialConfig, setUserLocation]);
};
