import {
    dayStringMultiple,
    dayStringSingle,
} from "../../next.config/routing/config";
import type { ForecastRoutingMatchers } from "../@types/forecast";
import type { CountryType, Nullable } from "../@types/generic";

export const getForecastRoutingMatchers = (
    country: CountryType
): ForecastRoutingMatchers => {
    return {
        dayStringMultiple:
            country === "pl" ? dayStringMultiple.pl : dayStringMultiple.dach,
        dayStringSingle: /*country === "pl" ? "jutro" :*/ dayStringSingle.dach,
    };
};
export const isForecastPage = (featureCode?: Nullable<string>): boolean => {
    if (featureCode === null) {
        return false;
    }
    const isCity = featureCode?.indexOf("P") === 0 && featureCode !== "PCLI";
    const isStation = featureCode === "STNM";
    const isAirport = featureCode === "AIRP";
    const isAmusementPark = featureCode === "AMUS";
    const isFestival = featureCode === "FEST";
    const isScientificResearchBase = featureCode === "STNB";
    const isFarm = featureCode === "FRM";

    return (
        isCity ||
        isStation ||
        isAirport ||
        isAmusementPark ||
        isFestival ||
        isScientificResearchBase ||
        isFarm
    );
};
