import { usePathname, useSelectedLayoutSegments } from "next/navigation";
import { routePrefixes } from "../../../next.config/routing/config";
import type { PageParams } from "../../app/types";
import { getForecastRoutingMatchers } from "../../helpers/forecast";
import { extractCountry } from "../../helpers/generic";
import { getWindow } from "../../helpers/getWindow";
import { isDachProduct } from "../../helpers/isDachProduct";
import type { PageType } from "./types";

export const useSetPageType = (params: PageParams): PageType => {
    const selectedLayoutSegments = useSelectedLayoutSegments();
    const pathname = usePathname();
    const window = getWindow();
    const country = extractCountry(
        window?.location.hostname ?? global.hostname,
        pathname
    );
    const matchers = getForecastRoutingMatchers(country);

    return {
        isPlainPage: pathname === "/__DEFAULT__/",
        isAnyForecastPage: selectedLayoutSegments[0] === "forecast",
        isForecastTodayPage:
            selectedLayoutSegments[0] === "forecast" &&
            selectedLayoutSegments[1] === "today",
        isForecastTomorrowPage:
            selectedLayoutSegments[0] === "forecast" &&
            selectedLayoutSegments[1] === "single" &&
            params.dayStringSingle === matchers.dayStringSingle,
        isForecastSinglePage:
            selectedLayoutSegments[0] === "forecast" &&
            selectedLayoutSegments[1] === "single",
        isForecastMultiplePage:
            selectedLayoutSegments[0] === "forecast" &&
            selectedLayoutSegments[1] === "multiple",
        isForecastWeekendPage:
            selectedLayoutSegments[0] === "forecast" &&
            selectedLayoutSegments[1] === "multiple" &&
            params.dayStringMultiple === matchers.dayStringMultiple,
        isHomePage:
            (country === "pl" && pathname === `/${routePrefixes.pl}/`) ||
            (isDachProduct(country) && pathname === "/"),
        isAnySkiAreasPage: selectedLayoutSegments[0] === "skiAreas",
        isAnySkiAreaDetailPage:
            selectedLayoutSegments[0] === "skiAreas" &&
            selectedLayoutSegments[1] === "detail",
        isSkiAreasPage:
            selectedLayoutSegments.length === 2 &&
            selectedLayoutSegments[0] === "skiAreas" &&
            selectedLayoutSegments[1] === "(index)",
        isSkiAreaDetailPage:
            selectedLayoutSegments.length === 3 &&
            selectedLayoutSegments[0] === "skiAreas" &&
            selectedLayoutSegments[1] === "detail" &&
            selectedLayoutSegments[2] === "(index)",
        isSkiAreaWeatherAndSnowPage:
            selectedLayoutSegments.length === 3 &&
            selectedLayoutSegments[0] === "skiAreas" &&
            selectedLayoutSegments[1] === "detail" &&
            selectedLayoutSegments[2] === "weatherAndSnow",
        isSkiAreaSlopesAndLiftsPage:
            selectedLayoutSegments.length === 3 &&
            selectedLayoutSegments[0] === "skiAreas" &&
            selectedLayoutSegments[1] === "detail" &&
            selectedLayoutSegments[2] === "slopesAndLifts",
        isAnyHealthPollenDictionaryPage:
            selectedLayoutSegments[2] === "pollenDictionary",
        isHealthPollenDictionaryPage:
            selectedLayoutSegments.length === 4 &&
            selectedLayoutSegments[2] === "pollenDictionary" &&
            selectedLayoutSegments[3] === "(index)",
        isHealthPollenDictionaryByCharacterPage:
            selectedLayoutSegments.length === 5 &&
            selectedLayoutSegments[2] === "pollenDictionary" &&
            selectedLayoutSegments[4] === "(index)",
        isHealthPollenDictionaryEntryPage:
            selectedLayoutSegments.length === 5 &&
            selectedLayoutSegments[2] === "pollenDictionary" &&
            selectedLayoutSegments[4] !== "(index)",
        isLivecamLandingPage:
            selectedLayoutSegments.length === 2 &&
            selectedLayoutSegments[0] === "hdLiveWebcams" &&
            selectedLayoutSegments[1] === "(index)",
        isLivecamDetailPage:
            selectedLayoutSegments.length === 2 &&
            selectedLayoutSegments[0] === "hdLiveWebcams" &&
            selectedLayoutSegments[1] !== "(index)" &&
            selectedLayoutSegments[1] !== "information",
        isLivecamInformationPage:
            selectedLayoutSegments.length === 2 &&
            selectedLayoutSegments[0] === "hdLiveWebcams" &&
            selectedLayoutSegments[1] === "information",
        isAnyLivecamPage: selectedLayoutSegments[0] === "hdLiveWebcams",
        isPrivacyPage:
            selectedLayoutSegments.length === 2 &&
            selectedLayoutSegments[0] === "(legal)" &&
            selectedLayoutSegments[1] === "privacy",
        isImprintPage:
            selectedLayoutSegments.length === 2 &&
            selectedLayoutSegments[0] === "(legal)" &&
            selectedLayoutSegments[1] === "imprint",
        isCompliancePage:
            selectedLayoutSegments.length === 2 &&
            selectedLayoutSegments[0] === "(legal)" &&
            selectedLayoutSegments[1] === "compliance",
    };
};
