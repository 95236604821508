import { usePathname, useSearchParams } from "next/navigation";
import React, {
    createContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import type { Nullable } from "../../@types/generic";
import type { PageParams } from "../../app/types";
import { useSetupBridge } from "../../bridge/hooks";
import type { InitialConfig, UserLocation } from "../../bridge/types";
import { debug } from "../../helpers/debug";
import { extractCountry } from "../../helpers/generic";
import { getWindow } from "../../helpers/getWindow";
import { sendError } from "../ErrorBoundary/helpers";
import { extractPageParamsFromPathname } from "./helper.pageParams";
import { useSetPageType } from "./helper.pageType";
import type { ApplicationStateContextType, PageType, PrebidAB } from "./types";

export const ApplicationStateContext =
    createContext<ApplicationStateContextType>({
        pageType: null,
        pageParams: {},
        country: "de",
        isMobileSafari: false,
        isPersistingForbidden: false,
        webview: null,
        initialConfig: null,
        userLocation: undefined,
        prebid: null,
        setPrebid: null,
    });

type ApplicationStateProps = {
    children: React.ReactNode;
};

export const ApplicationState: React.FC<ApplicationStateProps> = ({
    children,
}) => {
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const webview = useRef(searchParams.get("webview"));
    const pageType = useRef<Nullable<PageType>>(null);
    const pageParams = useRef<PageParams>({});
    const window = getWindow();
    const [initialConfig, setInitialConfig] =
        useState<Nullable<InitialConfig>>(null);
    const [userLocation, setUserLocation] = useState<
        UserLocation | undefined
    >();
    const [prebid, setPrebid] = useState<Nullable<PrebidAB>>(null);

    const country = useMemo(
        () =>
            extractCountry(
                window?.location.hostname ?? global.hostname,
                pathname
            ),
        [pathname, window?.location.hostname]
    );

    pageParams.current = extractPageParamsFromPathname(pathname, country);
    pageType.current = useSetPageType(pageParams.current);

    let isPersistingForbidden = false;
    try {
        window?.localStorage.getItem("wcom_opt_usr");
    } catch {
        isPersistingForbidden = true;
    }
    const isMobileSafari = useMemo(() => {
        return typeof window?.navigator.userAgent === "undefined"
            ? false
            : /^((?!(chrome|crios|firefox|fxios|edge|edgios)).)*safari/i.test(
                  window.navigator.userAgent
              );
    }, [window?.navigator.userAgent]);
    const [contextValue, setContextValue] =
        useState<ApplicationStateContextType>({
            pageType: pageType.current,
            pageParams: pageParams.current,
            country,
            isMobileSafari,
            isPersistingForbidden,
            webview: webview.current,
            initialConfig,
            userLocation,
            prebid,
            setPrebid,
        });

    useEffect(() => {
        const result = {
            country,
            isMobileSafari,
            isPersistingForbidden,
            webview: webview.current,
            initialConfig,
            userLocation,
            pageType: pageType.current,
            pageParams: pageParams.current,
            prebid,
            setPrebid,
        };
        // eslint-disable-next-line testing-library/no-debugging-utils
        debug("[app]: application state", result);
        setContextValue(result);
    }, [
        webview,
        pathname,
        pageType,
        pageParams,
        country,
        initialConfig,
        isMobileSafari,
        isPersistingForbidden,
        userLocation,
        prebid,
        setPrebid,
    ]);

    useSetupBridge(contextValue, setInitialConfig, setUserLocation);

    try {
        return (
            <ApplicationStateContext value={contextValue}>
                {children}
            </ApplicationStateContext>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
